import React from 'react'
import logo from '../img/logo-06.png'
import ig from '../img/ig-01.png'
import whatsapp from '../img/whatsapp-02.png'

function Footer() {
    return (
        <div className='footer container-fluid'>
            <div className='row align-items-center'>
                <div className='col-md-4 col-12 text-center'>
                    <img className='img-fluid' src={logo} />
                    <h3 className='footer-text'>Bellz & Berryz</h3>
                    <h1 className='slogan'>Muffins = Smiles</h1>
                </div>
                <div className='col-md-4 col-12 text-center'>
                    <h5 className='footer-text'>Your feedback is important to us. Please take the customer survey to get a free sample package on your next purchase from Bellz & Berryz</h5>
                    <a href='https://forms.gle/psrD2Wp2vKJY8u747' className='survey-btn'>
                        <h5 className='btn-txt'>TAKE SURVEY</h5>
                    </a>
                </div>
                <div className='col-md-4 col-12 text-center'>
                    <a href='https://www.instagram.com/bellznberryz'>
                        <img className='socials' src={ig} />
                    </a>
                    <a href='https://wa.me/18687620260'>
                        <img className='socials' src={whatsapp} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer