import axios from 'axios'
import React, { useEffect, useState } from 'react'

function OrderDetails(props) {

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = () => {
        axios.get(`${props.api}/api/singletons/get/orderdetails?token=${props.acctok}`)
            .then(res => {
                setDetails(res.data.Content)
            })
    }

    const [details, setDetails] = useState()

    return (
        <>
            <div className='text-center'>
                <h1>Order Details:</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: details }} />
        </>
    )
}

export default OrderDetails