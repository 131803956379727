import axios from 'axios'
import React, { useEffect, useState } from 'react'
import placeholder from '../img/placeholder.png'
import premium from '../img/premium.png'
import OrderDetails from './OrderDetails'

function Body(props) {

    const [sweetFlavours, setSweet] = useState()
    const [savoryFlavours, setSavory] = useState()
    const [treatBoxes, setTreatBoxes] = useState()


    useEffect(() => {
        getFlavors()
        getTreatz()
    }, [])

    const getFlavors = () => {
        axios.get(`${props.api}/api/collections/get/muffins?token=${props.acctok}`)
            .then(res => {
                res.data.entries.sort((a, b) => a.price - b.price)
                const sweet = res.data.entries.filter(y => y.savory === false)
                const savory = res.data.entries.filter(y => y.savory === true)
                setSweet(sweet)
                console.log(sweet)
                setSavory(savory)
            })
    }

    const getTreatz = () => {
        axios.get(`${props.api}/api/collections/get/treatz?token=${props.acctok}`)
            .then(res => {
                res.data.entries.sort((a, b) => a.price - b.price)
                setTreatBoxes(res.data.entries)
            })
    }

    return (
        <div className='container-fluid mt-4'>
            <h1>Sweet Muffins:</h1>
            <div className='row mt-4'>
                {sweetFlavours === undefined ? <h4>Loading...</h4> :
                    sweetFlavours.map(muffin => (
                        <>
                            <div key={muffin._id} className='col-12 col-md-6 muffin-card'>
                                <div className='shadow'>
                                    <div className='row no-gutters align-items-center justify-content-center'>
                                        <div className='col-md-4 col-12'>
                                            {
                                                muffin.img ?
                                                    <div className='image-parent'><img className='img-fluid prod-img' src={`${props.api}/${muffin.img.path}`} />
                                                        {muffin.pre ? <img className='premium' src={premium} /> : null}
                                                    </div>
                                                    : <img className='img-fluid' src={placeholder} />
                                            }
                                        </div>
                                        <div className='col-md-6 col-12 px-2'>
                                            <h4>{muffin.name}</h4>
                                            <p>{muffin.description}</p>
                                        </div>
                                        <div className='text-center'>
                                            <div className='col-md-2 col-12 mb-2'>
                                                <div className='sprice'>
                                                    <h2>${muffin.price}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
            <h1>Savory Muffins:</h1>
            <div className='row mt-4'>
                {savoryFlavours === undefined ? <h4>Loading...</h4> :
                    savoryFlavours.map(muffin => (
                        <>
                            <div key={muffin._id} className='col-12 col-md-6 muffin-card'>
                                <div className='shadow'>
                                    <div className='row no-gutters align-items-center justify-content-center'>
                                        <div className='col-md-4 col-12'>
                                            {muffin.img ? <img className='img-fluid prod-img' src={`${props.api}/${muffin.img.path}`} /> : <img className='img-fluid' src={placeholder} />}
                                        </div>
                                        <div className='col-md-6 col-12 px-2'>
                                            <h4>{muffin.name}</h4>
                                            <p>{muffin.description}</p>
                                        </div>
                                        <div className='text-center'>
                                            <div className='col-md-2 col-12 mb-2'>
                                                <div className='sprice'>
                                                    <h2>${muffin.price}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
            <h1>Treat Boxes:</h1>
            <div className='row mt-4'>
                {treatBoxes === undefined ? <h4>Loading...</h4> :
                    treatBoxes.map(muffin => (
                        <>
                            <div key={muffin._id} className='col-12 col-md-6 muffin-card'>
                                <div className='shadow'>
                                    <div className='row no-gutters align-items-center justify-content-center'>
                                        <div className='col-md-4 col-12'>
                                            {
                                                muffin.img ?
                                                    <img className='img-fluid prod-img' src={`${props.api}/${muffin.img.path}`} />
                                                    : <img className='img-fluid' src={placeholder} />
                                            }
                                        </div>
                                        <div className='col-md-6 col-12 px-2'>
                                            <h4>{muffin.name}</h4>
                                            <p>{muffin.description}</p>
                                        </div>
                                        <div className='text-center'>
                                            <div className='col-md-2 col-12 mb-2'>
                                                <div className='sprice'>
                                                    <h2>${muffin.price}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
            <OrderDetails api={props.api} acctok={props.acctok} />
        </div>
    )
}

export default Body