import React from 'react'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

function Home(props) {    
    return (
        <>
            <Header />
            <Body api={props.api} acctok={props.acctok} />
            <Footer />
        </>
    )
}

export default Home