import React from 'react'
import logo from '../img/logo_fin.png'

function Header() {
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-md-3 col-12 text-center'>
                        <img className='img-fluid' src={logo} />
                    </div>
                    <div className='col'></div>
                </div>
                <div className='container-fluid text-center'>
                    <h1>Bellz & Berryz</h1>
                    <h3>Freshly baked home-made breakfast treats</h3>
                    <h3>1-868-762-0260</h3>
                    <h3>bellznberryz@gmail.com</h3>
                </div>
            </div>
        </>
    )
}

export default Header