import './css/style.css';
import Home from './components/Home';

function App() {
  const links = {
    api: 'https://bellzcms.buzzolution.com',
    acctok: 'c403548206e4d3173ed4bad981792d'
  }
  return (
    <Home api={links.api} acctok={links.acctok} />
  );
}

export default App;
